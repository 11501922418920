import React from "react";
import packageJson from '../../package.json';

const AppInfoPage = () => {
    return <div>
        <div className={'container'}>
            <div className={'row p-4'}>
                <h1>App Info</h1>
                <table className={'table'}>
                    <tbody>
                        <tr>
                            <td>Name</td>
                            <td>{packageJson.name}</td>
                        </tr>
                        <tr>
                            <td>Version</td>
                            <td>{packageJson.version}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>
}

export default AppInfoPage;