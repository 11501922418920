import React from "react";

const Footer = () => {
    return <footer className="text-center bg-dark py-3">
        <div className="container">
            <p className={'mb-0'} style={{color: "rgb(255,255,255)"}}>Made with ❤️ {process.env.REACT_APP_DASH_DOMAIN}</p>
        </div>
    </footer>
}

export default Footer;