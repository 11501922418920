import React from "react";

const NavBar = () => {
    return <nav className="navbar navbar-light bg-transparent navbar-expand-md">
        <div className={'container'}>
            <a className="navbar-brand" href="/">
                {/*<img src="logo.jpg" width="40" height="40" alt=""/>*/}
                {/*&nbsp;*/}
                <span style={{fontFamily: "Montserrat Alternates, sansSerif"}}>KgpLife</span>
            </a>
        </div>
    </nav>
}

export default NavBar;