import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import Layout from "./components/Layout";
import Error404Page from "./pages/Error404Page";
import LandingPage from "./pages/LandingPage";
import AppInfoPage from "./pages/AppInfoPage";

function App() {
  return (
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route path="/" element={<LandingPage/>}/>
          <Route path="/info" element={<AppInfoPage/>}/>

          {/*Catch all route*/}
          <Route path="*" element={<Error404Page/>}/>
        </Route>
      </Routes>
  );
}

export default App;

