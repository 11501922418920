import React from "react";

interface ProductCardProps {
    icon: string,
    title: string,
    subtitle: string,
    description: string
    link: string
}

const ProductCard = ({icon, link, title, subtitle, description}: ProductCardProps) => {
    return (
        // <a href={link}
        //       target={'_blank'}
        //       rel="noreferrer"
        //       className={'card p-3 text-decoration-none text-dark'}>
        <>
            <div className={'product-card-header mb-3'}>
                <img src={icon}
                     alt={'logo'}
                     className={'card-logo'}/>
                &nbsp;
                &nbsp;
                <div>
                    <h5 className={'mb-0'}>{title}</h5>
                    <p className={'mb-0'}>{subtitle}</p>
                </div>
            </div>
            <p style={{fontFamily: "Roboto, sans-serif"}}>
                {description}
            </p>
        </>

    )
}

export default ProductCard;