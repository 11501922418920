import React from "react";
import ProductCard from "./ProductCard";

const LandingSection = () => {

    return <section className={'page-container'}>
        <div className="container">
            <div className="row inner-page">

                <div className={'text-center hero-header'}>
                    <h2 className="display-5 text-uppercase fw-bold mb-3">Kgp Life</h2>
                    <p className="text-center mb-4" style={{fontFamily: "Roboto, sans-serif"}}>
                        All about IIT Kgp Life
                    </p>
                </div>

                <div className={'product-container'}>
                    <ProductCard icon={'erp_alerts_logo.png'}
                                 title={'ERP Alerts'}
                                 link={'https://erpalerts.kgplife.com'}
                                 description={'Get all placement and internship related notifications in a single app.'}
                                 subtitle={'App'}/>
                    <ProductCard icon={'paperhub_logo.png'}
                                 title={'Paper Hub'}
                                 description={'Get all the previous year question papers of IIT Kharagpur.'}
                                 link={'https://paperhub.kgplife.com'}
                                 subtitle={'Website'}/>

                    {/*<ProductCard icon={'oreo_logo2.png'}*/}
                    {/*             title={'Buy Oreo'}*/}
                    {/*             description={'Central subscription portal to buy oreo.'}*/}
                    {/*             link={'https://oreo.kgplife.com'}*/}
                    {/*             subtitle={'Platform'}/>*/}
                </div>
            </div>
        </div>
    </section>
}

export default LandingSection;